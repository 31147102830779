import mixpanel from "mixpanel-browser";
import { getAllSources, getIsOnpremise, getPlatform, isWebView } from ".";
import constants from "./constants";
var PROD = "production";
var LOCALSTORAGE = "localStorage";
function getUserProperties(userData) {
  var sources = getAllSources(userData);
  var sourceIds = sources.map(function (source) {
    return source.uuid;
  });
  var PLATFORMS = constants.PLATFORMS;
  var platform = Object.keys(PLATFORMS).find(function (p) {
    return PLATFORMS[p] === getPlatform();
  });
  return {
    Name: userData.details.name,
    Email: userData.details.email,
    sourceIds: sourceIds,
    isMobielApp: isWebView(),
    platform: platform
  };
}
function initMixpanel() {
  var MIXPANEL_TOKEN = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;
  var isProduction = process.env.NEXT_PUBLIC_APP_ENV === PROD;
  var isOnPremise = getIsOnpremise();
  if (isOnPremise || !MIXPANEL_TOKEN) {
    return function () {};
  }
  mixpanel.init(MIXPANEL_TOKEN, {
    debug: !isProduction,
    track_pageview: false,
    persistence: LOCALSTORAGE
  });
  return function (userData) {
    mixpanel.identify(userData.username);
    var userProperties = getUserProperties(userData);
    mixpanel.people.set_once(userProperties);
  };
}
export default initMixpanel;