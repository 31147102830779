import React, { forwardRef, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import "react-datepicker/dist/react-datepicker.css";
import {
  faCalendarDay,
  faChevronLeft,
  faChevronRight,
  faClock,
} from "@fortawesome/pro-regular-svg-icons";
import AwesomeIcon from "../AwesomeIcon";
import { addDays, isFuture, isToday, subDays } from "date-fns";
import { getDateFromDatetime } from "../../utils";

const DateTimeInput = ({
  label,
  value,
  onChange,
  id,
  dateFormat,
  datePlaceholder,
  timeFormat,
  timePlaceholder,
  className,
  required,
  unit,
  disabled,
  isClinicalField,
  wrapperClassName,
  inputClassName,
  ctaClassName,
  showTimeFirst,
  hideTime,
  allowFutureDate = false,
}) => {
  let dateInput = useRef(null);
  let timeInput = useRef(null);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);
  const [dateTime, setDateTime] = useState(null);
  let minTime = null;
  let maxTime = null;
  let maxDate = null;
  if (!allowFutureDate) {
    if (!date || isToday(date) || isFuture(date)) {
      minTime = new Date(0, 0, 0, 0, 0);
      maxTime = new Date();
      maxTime.setHours(maxTime.getHours());
      maxTime.setMinutes(maxTime.getMinutes());
    }
    maxDate = new Date();
    if (time && new Date(time) >= new Date()) {
      maxDate.setDate(maxDate.getDate() - 1);
    }
  }

  useEffect(() => {
    if (dateInput.current) {
      dateInput.current.input.readOnly = true;
    }
    if (timeInput.current) {
      timeInput.current.input.readOnly = true;
    }
  }, []);

  useEffect(() => {
    let _date = null;
    let _time = null;
    if (value) {
      _date = new Date(value);
      // always considering time control for todays date so that is it easier to handle
      _time = new Date();
      _time.setHours(_date.getHours());
      _time.setMinutes(_date.getMinutes());
    }
    setDate(_date);
    setTime(_time);
    onChangeDateTime(_date, _time, false);
  }, [value]);

  const onChangeDateTime = (_date, _time, callProps = true) => {
    const _dateTime = _date;
    if (_dateTime && _time) {
      _dateTime.setHours(_time.getHours());
      _dateTime.setMinutes(_time.getMinutes());
    }
    setDateTime(_dateTime);
    if (callProps) {
      onChange(_dateTime);
    }
  };

  const onChangeDate = (_value) => {
    let _date = _value ? new Date(_value) : null;
    setDate(_date);
    onChangeDateTime(_date, time);
  };

  const onChangeTime = (_value) => {
    let _time = _value ? new Date(_value) : null;
    if (
      _time &&
      !allowFutureDate &&
      (isFuture(date) || isToday(date)) &&
      _time > maxTime
    ) {
      _time = maxTime;
    }
    setTime(_time);
    onChangeDateTime(date, _time);
  };

  const onNextDate = () => {
    const _date = addDays(date, 1);
    setDate(_date);
    onChangeDateTime(_date, time);
  };

  const onPrevDate = () => {
    const _date = subDays(date, 1);
    setDate(_date);
    onChangeDateTime(_date, time);
  };

  return (
    <div
      className={classNames(
        "relative rounded-md px-2 max-w-lg text-gray-900 dark:text-gray-100",
        {
          "border border-gray-500": !isClinicalField,
          "opacity-50": disabled,
          [wrapperClassName]: !!wrapperClassName,
        }
      )}
    >
      <label
        className={classNames("text-md text-left", {
          "bg-gray-800 absolute left-4 -top-3 px-4": !isClinicalField,
          "bg-transparent flex mb-5 !text-base": isClinicalField,
        })}
      >
        {label} {!!required && <span className="text-red-500">*</span>}
      </label>
      <div
        className={classNames(
          "flex w-full items-center gap-5 sm:gap-6 flex-col sm:flex-row",
          { "flex-col-reverse sm:flex-row-reverse": showTimeFirst }
        )}
      >
        <div
          className={classNames("flex grow items-center gap-3", { "grow-0": hideTime })}
        >
          <button
            className={classNames(
              "h-[39px] w-[39px] border border-gray-800 bg-gray-100 dark:bg-gray-800 rounded-6 p-2 disabled:opacity-50 disabled:cursor-not-allowed",
              { [ctaClassName]: !!ctaClassName }
            )}
            disabled={!dateTime}
            onClick={(e) => {
              onPrevDate();
              e.preventDefault();
            }}
          >
            <AwesomeIcon name={faChevronLeft} />
          </button>
          <div
            className={classNames("flex items-center grow rounded", {
              "bg-gray-100 border border-gray-300 dark:border-none dark:bg-gray-800 py-1 px-3":
                isClinicalField,
              [className]: !!className,
              [ctaClassName]: !!ctaClassName,
            })}
          >
            <AwesomeIcon name={faCalendarDay} />
            <DatePicker
              id={id}
              ref={dateInput}
              selected={date ? new Date(date) : date}
              onChange={onChangeDate}
              placeholderText={datePlaceholder || dateFormat}
              required={required}
              dateFormat={dateFormat}
              disabled={disabled}
              autoComplete="off"
              maxDate={maxDate}
              className={classNames("flex grow bg-transparent w-full border-0 pb-3", {
                "text-center !p-2 mb-1": isClinicalField,
                [inputClassName]: !!inputClassName,
              })}
            />
          </div>
          <button
            className={classNames(
              "h-[39px] w-[39px] border border-gray-800 bg-gray-100 dark:bg-gray-800 rounded-6 p-2 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed",
              { [ctaClassName]: !!ctaClassName }
            )}
            onClick={(e) => {
              onNextDate();
              e.preventDefault();
            }}
            disabled={
              !dateTime ||
              (!allowFutureDate &&
                maxDate &&
                getDateFromDatetime(dateTime) >= getDateFromDatetime(maxDate))
            }
          >
            <AwesomeIcon name={faChevronRight} />
          </button>
        </div>
        <div
          className={classNames("flex items-center grow rounded w-full sm:w-auto", {
            "bg-gray-100 border border-gray-300 dark:border-none dark:bg-gray-800 py-1 px-3":
              isClinicalField,
            [className]: !!className,
            [ctaClassName]: !!ctaClassName,
            hidden: hideTime,
          })}
        >
          <AwesomeIcon name={faClock} />
          <DatePicker
            ref={timeInput}
            selected={time ? new Date(time) : time}
            onChange={onChangeTime}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={5}
            timeCaption="Time"
            dateFormat={timeFormat}
            minTime={minTime}
            maxTime={maxTime}
            autoComplete="off"
            placeholderText={timePlaceholder || timeFormat}
            calendarClassName="flex items-center justify-center"
            className={classNames("block bg-transparent w-full border-0 pb-3", {
              "text-center !p-2 mb-1": isClinicalField,
              [inputClassName]: !!inputClassName,
            })}
          />
        </div>
        {!!unit && <span className="text-md px-3 text-gray-400">{unit}</span>}
      </div>
    </div>
  );
};

export default DateTimeInput;
